import classNames from "classnames";
import React from "react";

import Image from "components/imageNew/image";
import type { FullDatoImage } from "lib/types/datoBlocks";

import RootComponent from "./rootComponent/rootComponent";

type FeatureCardProps = {
  component: "button" | "link";
  title: string;
  wrapperClasses?: string;
  brandingColor?: "cerise" | "navy";
  tag?: string;
  excerpt?: string;
  isSponsored?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  sponsorInfo?: {
    name?: string;
    url?: string;
    logo?: FullDatoImage;
    sponsorshipType?: "Sponsored by" | "In partnership with" | null | undefined;
  };
  backgroundImage?: FullDatoImage & {
    sizes?: string;
  };
  withPaywall?: boolean;
  href?: string;
  paywallHeading?: string;
  onClick?: () => void;
};

const FeatureCard = ({
  component,
  title,
  wrapperClasses,
  brandingColor = "navy",
  tag,
  excerpt,
  isSponsored,
  sponsorInfo,
  leftLabel,
  rightLabel,
  backgroundImage,
  withPaywall = false,
  href,
  paywallHeading = "Join to access",
  onClick,
}: FeatureCardProps) => {
  return (
    <RootComponent
      href={href}
      component={component}
      className={classNames(
        "group relative block h-full w-full bg-white text-left",
        "after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:opacity-0 after:transition-opacity after:content-['']",
        "focus:outline-none focus-visible:outline-none active:after:opacity-30",
        wrapperClasses,
        {
          "after:bg-cerise": brandingColor === "cerise",
          "after:bg-pro-blue": brandingColor === "navy",
        }
      )}
      onClick={onClick}
      data-color={brandingColor}
    >
      <>
        {withPaywall && (
          <div
            className={classNames(
              "pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full opacity-0 transition-opacity duration-200",
              "group-hover:opacity-100 group-focus-visible:opacity-100"
            )}
          >
            <div
              className={classNames(
                "flex h-full w-full items-center justify-center bg-white bg-opacity-[0.94] font-bold text-[24px]",
                "group-hover:ring-3 group-focus-visible:ring-3 group-hover:ring group-hover:ring-inset group-focus-visible:ring group-focus-visible:ring-inset",
                {
                  "text-pro-blue ring-pro-blue": brandingColor === "navy",
                  "text-cerise ring-cerise": brandingColor === "cerise",
                }
              )}
            >
              {paywallHeading}
            </div>
          </div>
        )}
        <div
          data-testid={"featureCard__image"}
          className={classNames(
            "absolute bottom-0 left-0 right-0 top-0 z-[0] h-full w-full object-cover",
            {
              "bg-[#EBEBF0]": !backgroundImage || !backgroundImage.url,
              "bg-[#FFFFFF]": backgroundImage && backgroundImage.url,
            }
          )}
        >
          {backgroundImage && backgroundImage.url && (
            <Image
              src={backgroundImage.url}
              alt={backgroundImage.alt}
              width={backgroundImage.width}
              height={backgroundImage.height}
              blurDataURL={backgroundImage.blurUpThumb || undefined}
              className={"pointer-events-none h-100 w-100 object-cover"}
              sizes={backgroundImage.sizes}
            />
          )}
        </div>
        {tag && (
          <div
            className={classNames(
              "absolute left-[12px] top-[12px] bg-pro-blue px-[6px] py-[6px] font-bold text-[14px] leading-[130%] text-white",
              {
                "bg-pro-blue": brandingColor === "navy",
                "bg-cerise": brandingColor === "cerise",
              }
            )}
          >
            {tag}
          </div>
        )}
        <div
          className={classNames(
            "z-1 relative flex h-full items-end p-3 transition-[box-shadow] ",
            "group-hover:ring-3 group-hover:ring-inset",
            "group-focus-visible:ring-3 group-focus-visible:outline-none group-focus-visible:ring group-focus-visible:ring-inset",
            {
              "group-hover:ring-cerise group-focus-visible:ring-cerise":
                brandingColor === "cerise",
              "group-hover:ring-pro-blue group-focus-visible:ring-pro-blue":
                brandingColor === "navy",
              "group-hover:ring": !withPaywall,
            }
          )}
        >
          <div className={"flex w-full flex-col gap-[14px] bg-white p-[14px]"}>
            <div className={"flex flex-col gap-[6px]"}>
              {(leftLabel || rightLabel) && (
                <div
                  className={"flex justify-between"}
                  data-testid={"featureCard__topMeta"}
                >
                  <div className={"font-bold text-[14px] text-mono-40"}>
                    {leftLabel}
                  </div>
                  <div className={"text-[14px] text-mono-40"}>{rightLabel}</div>
                </div>
              )}
              <p className={"font-recklessBold text-[22px] leading-[120%]"}>
                {title}
              </p>
            </div>
            {excerpt && (
              <p className={"line-clamp-3 text-[16px] leading-[130%]"}>
                {excerpt}
              </p>
            )}
            {isSponsored && sponsorInfo && sponsorInfo.sponsorshipType && (
              <div className={"flex items-center justify-between"}>
                <p className={"text-[14px] leading-[130%] text-[#5C5C66]"}>
                  {sponsorInfo.sponsorshipType}
                </p>
                {sponsorInfo.logo && (
                  <div className={"h-[36px] w-[90px] min-w-[90px]"}>
                    <Image
                      src={sponsorInfo.logo.url}
                      alt={sponsorInfo.logo.alt}
                      width={sponsorInfo.logo.width}
                      height={sponsorInfo.logo.height}
                      blurDataURL={sponsorInfo.logo.blurUpThumb || undefined}
                      className={"pointer-events-none h-100 w-100 object-cover"}
                      sizes={"200px"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </RootComponent>
  );
};

export default FeatureCard;
