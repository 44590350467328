import ArticleCard from "components/articleCard/articleCard";
import type { ArticleList } from "lib/types/article";

type MixPanelData = {
  [key: string]: any;
};

type NewsGridProps = {
  articles: ArticleList["articles"];
  gaArticleClassName?: string;
  gaArticleSectorClassName?: string;
  onArticleClick: (data: MixPanelData, type: "url" | "sector") => Promise<void>;
};

const NewsGrid = ({
  articles,
  onArticleClick,
  gaArticleClassName,
  gaArticleSectorClassName,
}: NewsGridProps) => {
  return (
    <div className={"w-100"}>
      <ul
        className={
          "grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-1 lg:gap-y-4"
        }
        aria-label={"List of featured articles"}
      >
        {articles.map((article) => {
          return (
            <li key={article.id} className={"flex h-[207px] lg:h-[270px]"}>
              <ArticleCard
                mainLinkClasses={gaArticleClassName}
                sectorClasses={gaArticleSectorClassName}
                variant={"large"}
                withExcerpt={true}
                truncateAuthors={true}
                onTrackingEventClicked={onArticleClick}
                {...article}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NewsGrid;
