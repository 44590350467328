import React from "react";
import classNames from "classnames";

type RootComponentProps = {
  component: "button" | "link";
  children: React.ReactElement;
  className: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
};
const RootComponent = ({
  component,
  className,
  onClick,
  href,
  children,
  disabled = false,
  ...rest
}: RootComponentProps) => {
  if (component === "button") {
    return (
      <button
        type={"button"}
        className={className}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
  return (
    <a
      href={href}
      className={classNames(className, {
        "pointer-events-none": disabled,
      })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  );
};

export default RootComponent;
